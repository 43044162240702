import {ListItem, ListItemText} from '@mui/material';
import {useCallback} from 'react';
import * as React from 'react';
import {OnEnterPressWrapper} from '~/helpers';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/ScopeAttachments.module.scss';
import {ScopeFile} from '~/types';

interface ScopeAttachmentProps {
    file: ScopeFile;
    setBusy: (busy: boolean) => void;
}

export default function ScopeAttachment({file, setBusy}: ScopeAttachmentProps): JSX.Element {
    const downloadFile = useStoreActions((actions) => actions.shared.downloadFile);

    const handleClick = useCallback(
        async (e: React.UIEvent) => {
            if (file.url.includes('hm.hosted-by-files.com')) {
                window.open(file.url, '_blank');
            } else {
                e.stopPropagation();
                setBusy(true);
                await downloadFile(file.url);// getAttachment(file.url);
                setBusy(false);
            }
        },
        [file.url, downloadFile, setBusy],
    );

    return (
        <ListItem key={file.id}>
            <ListItemText
                primary={
                    <div className={styles.scopeAttachmentListItem}>
                        <div onClick={handleClick} onKeyDown={OnEnterPressWrapper(handleClick)} role="button" tabIndex={0}>
                            <h4 className={styles.fileName}> {file.fileName}</h4>
                            <span style={{fontSize: 11}}>{new Date(Number(file?.url?.replace(/^.+?\/(\d+).+$/,"$1") ?? "0")).toLocaleString()}</span>
                        </div>
                    </div>
                }
            />
        </ListItem>
    );
}

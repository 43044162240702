
import {KeyboardEvent, UIEvent} from 'react';

export function GetTempId<T extends {}>(array: T[], property: keyof T): number {
    let minValue = -1;
    array.forEach((e) => {
        const v = e[property];
        if (typeof v === 'number' && v <= minValue) {
            minValue = v - 1;
        }
    });

    return minValue;
}

export function htmlDecode(input: string){
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
}

export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function DisableMobileZoom(): void {
    // todo: fix commonents that dont scale.
    // user scalable should never be disabled for accessability reasons
    document.head.querySelector('meta[name=viewport]').remove();
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1.0, user-scalable=0';
    document.head.prepend(metaTag);
}

export const defaultMediaSizes = {
    xxs: '(max-width: 450px)',
    xs: '(max-width: 800px)',
    sm: '(max-width: 1160px)',
    md: '(max-width: 1500px)',
    lg: '(max-width: 1960px)',
}

export function EnableMobileZoom(): void {
    // todo: fix commonents that dont scale.
    // user scalable should never be disabled for accessability reasons
    document.head.querySelector('meta[name=viewport]').remove();
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1.0, user-scalable=1';
    document.head.prepend(metaTag);
}

export function AxiosTryCatchBlock<T>(fn: () => T): T {
    // todo: this adds a stack to the error message and makes it harder to parse.
    // should be removed.
    try {
        return fn();
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
    }
}

export function HtmlToString(html: string): string {
    return html.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
}

export function formatPhoneNumber(phoneNumberString: string): string {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
}

export function CleanSunEditorHTML(html: string): string {
    if (!html) {
        return '';
    }
    return html
        .replaceAll(/contenteditable="\w*"/gm, '') // Replace content editable
        .replaceAll(/"color: rgb\(\d{1,3}, ?\d{1,3}, ?\d{1,3}\);/gm, '"color: #FFFFFF;') // Change font color to white
        .replaceAll(/background-color: rgb\(\d{1,3}, ?\d{1,3}, ?\d{1,3}\);/gm, 'background-color: transparent;') // change background to #121212
        .replaceAll(/font-family: ?&quot;.*font-size: ?\d*px;/gm, ''); // Get rid of font family and font size
}

export function CPPValidStatus(status: string): string {
    switch (status) {
        case 'Not Awarded':
        case 'Cancelled':
        case 'No Bid':
            return 'Closed';
        case 'Ready For Close Out':
            return 'Work Complete';
        case 'Prepare Bid':
        case 'Opportunity':
            return 'Pre Bid';
        case 'Project Setup':
            return 'Active';
        default:
            return status;
    }
}

export function GetOptionLabel<T extends {title?: string}>(obj: string | T): string {
    return typeof obj === 'string' ? obj : obj.title;
}

export function IsOptionEqualToValue<T extends {id: number; title?: string}>(obj: string | T, val: string | T): boolean {
    if (typeof obj === 'object') {
        return typeof val === 'object' ? obj.id === val.id : obj.title === val;
    }

    return typeof val === 'object' ? obj === val.title : obj === val;
}

export function OnEnterPressWrapper(fn: (e: UIEvent) => void): (e: KeyboardEvent) => void {
    return (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            fn(event);
        }
    };
}

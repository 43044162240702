import DateFnsUtils from '@date-io/date-fns';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Zoom,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {useForm, FormProvider, Message} from 'react-hook-form';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import { trackEvent } from '~/api';
import AttachmentListItem from '~/components/generics/AttachmentListItem';
import BusyButton from '~/components/generics/BusyButton';
import FileDropZone from '~/components/generics/FIleDropZone';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import RHFController from '~/components/generics/RHFController';
import SignatureControl from '~/components/generics/SignatureControl';
import {CleanSunEditorHTML, DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceDialog.module.scss';
import {CorrespondenceAttachment, UploadedFile, TQ} from '~/types';

let scrollPos = 0;

export default function TQDialog(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const {projectId} = useParams<{projectId: string}>();
    const [busy, setBusy] = useState(false);
    const tqs = useStoreState((state) => state.correspondence.tqs);
    const uploadFiles = useStoreActions((actions) => actions.shared.uploadFiles);
    const project = useStoreState((state) => state.subsite.project);
    const updateTQ = useStoreActions((actions) => actions.correspondence.updateTQ);

    const tqId = searchParams.get('tq');
    const tq = useMemo(() => tqs.find((o) => tqId && o.id === +tqId), [tqId, tqs]);

    const methods = useForm<TQ>({
        defaultValues: tq ?? {},
    });
    const {reset, handleSubmit} = methods;

    const requestorAttachments = useMemo((): CorrespondenceAttachment[] => tq?.attachments?.filter((a) => a.type === 'H+M') ?? [], [tq]);

    const responderAttachments = useMemo((): CorrespondenceAttachment[] => tq?.attachments?.filter((a) => a.type === 'Client') ?? [], [tq]);

    const [files, setFiles] = useState<File[]>([]);
    const {enqueueSnackbar} = useSnackbar();

    // Stop scrolling when the action item dialog is open
    useEffect(() => {
        if (tqId) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
    }, [tqId]);

    // Reset RHF state when tq changes
    useEffect(() => {
        reset(tq);
    }, [tq, reset]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
            setBusy(false);
        };
    }, []);

    const handleFileSelection = useCallback((f: File[]): void => {
        setFiles(f);
    }, []);

    const handleClose = useCallback(() => {
        setSearchParams({});
    }, [setSearchParams]);

    const handlePrintPreview = useCallback(() => {
        navigate(`/print/${projectId}/tq/${tqId}`, {state: {from: location}});
    }, [location, navigate, projectId, tqId]);

    const radioGroupBooleanOnChange = (e: React.ChangeEvent<HTMLInputElement>, v: string, onChange: (...event: any[]) => void): void => {
        onChange(v === 'true');
    };

    const onSubmit = handleSubmit(async (data) => {
        setBusy(true);
        let uploadedFiles: UploadedFile[] = [];
        if (files.length > 0 && project?.projectNumber) {
            trackEvent('upload TQ attachments', {id: data.id, number: data.number, files: files.map(o => o.name)});
            uploadedFiles = await uploadFiles({destination: `/Development/Project/${project.projectNumber}/TQ/`, files});
        }

        trackEvent('update TQ', {id: data.id, number: data.number});
        const {result, message} = await updateTQ({tq: data, uploadedFiles});
        setBusy(false);

        enqueueSnackbar(message, {variant: result});
        if (result === 'success') {
            handleClose();
        }
    });

    return (
        <Dialog open={tqId != null} onClose={handleClose} id="tq-dialog-root" className={styles.root} maxWidth="md" TransitionComponent={Zoom}>
            <DialogTitle>
                <div className={styles.title}>
                    <h3>Technical Query</h3>
                    {!tq?.editable && <FontAwesomeIcon icon={['fal', 'lock-alt']} color="tomato" />}
                </div>
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <form onSubmit={onSubmit}>
                            {tq == null ? (
                                <NoDataPlaceholder title="No TQ Data Available" />
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Recipient" disabled variant="standard" />}
                                            name="recipient"
                                            defaultValue={tq.recipient ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Status" disabled variant="standard" />}
                                            name="status"
                                            defaultValue={tq.status ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Number" disabled variant="standard" />}
                                            name="number"
                                            defaultValue={tq.number ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Rev" disabled variant="standard" />}
                                            name="rev"
                                            defaultValue={tq.rev ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="PO Number" disabled variant="standard" />}
                                            name="poNumber"
                                            defaultValue={tq.poNumber ?? ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Title" disabled variant="standard" />}
                                            name="title"
                                            defaultValue={tq.title ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={tq.disciplines}
                                                    multiple
                                                    renderInput={(params): JSX.Element => (
                                                        <TextField {...params} variant="standard" label="Disciplines" disabled />
                                                    )}
                                                    ChipProps={{size: 'small'}}
                                                    disabled
                                                />
                                            )}
                                            name="disciplines"
                                            defaultValue={tq.disciplines ?? []}
                                        />
                                    </Grid>
                                    <div className={styles.sectionDivider}>
                                        <h5>Part I - By H+M</h5>
                                    </div>
                                    <Grid item xs={4}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField {...field} variant="standard" label="Initiated By" disabled />
                                            )}
                                            name="initiatedBy"
                                            defaultValue={tq.initiatedBy ?? ''}
                                            rules={{
                                                required: 'Name is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField
                                                    {...field}
                                                    label="Date Initiated"
                                                    disabled
                                                    value={field.value ? new Date(field.value).toLocaleDateString() : ''}
                                                    variant="standard"
                                                />
                                            )}
                                            name="initiated"
                                            defaultValue={tq.initiated ?? ''}
                                            rules={{
                                                required: 'Date is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField
                                                    {...field}
                                                    label="Respond By"
                                                    disabled
                                                    value={field.value ? new Date(field.value).toLocaleDateString() : ''}
                                                    variant="standard"
                                                />
                                            )}
                                            name="responseRequired"
                                            defaultValue={tq.responseRequired ?? ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={tq.affectedDocuments}
                                                    multiple
                                                    renderInput={(params): JSX.Element => (
                                                        <TextField {...params} variant="standard" label="Drawing Numbers" disabled />
                                                    )}
                                                    ChipProps={{size: 'small'}}
                                                    disabled
                                                />
                                            )}
                                            name="affectedDocuments"
                                            defaultValue={tq.affectedDocuments ?? []}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p>Redline Required</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio disabled />}
                                                            label="No"
                                                            labelPlacement="top"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="redlineRequired"
                                            defaultValue={tq.redlineRequired ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>H+M Request / Question</p>
                                                    <SunEditor
                                                        disable
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="description"
                                            defaultValue={tq.description ?? ''}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p>Sketch Attached</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio disabled />}
                                                            label="No"
                                                            labelPlacement="top"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="sketchAttached"
                                            defaultValue={tq.sketchAttached ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p>Pictures Attached</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio disabled />}
                                                            label="No"
                                                            labelPlacement="top"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="picturesAttached"
                                            defaultValue={tq.picturesAttached ?? null}
                                        />
                                    </Grid>
                                    {requestorAttachments.length > 0 && (
                                        <Grid item xs={12} id="requestor-attachments">
                                            <div className={styles.attachmentList}>
                                                <p>Attachments</p>
                                                <ul>
                                                    {requestorAttachments.map(
                                                        (f): JSX.Element => (
                                                            <AttachmentListItem file={f} key={f.id} />
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.formGroup}>
                                                    <p>Type Of Impact</p>
                                                    <FormGroup {...field} row>
                                                        <FormControlLabel
                                                            value="Engineering"
                                                            control={
                                                                <Checkbox disabled checked={field.value && field.value.includes('Engineering')} />
                                                            }
                                                            label="Engineering"
                                                        />
                                                        <FormControlLabel
                                                            value="Fabrication"
                                                            control={
                                                                <Checkbox disabled checked={field.value && field.value.includes('Fabrication')} />
                                                            }
                                                            label="Fabrication"
                                                        />
                                                        <FormControlLabel
                                                            value="Construction"
                                                            control={
                                                                <Checkbox disabled checked={field.value && field.value.includes('Construction')} />
                                                            }
                                                            label="Construction"
                                                        />
                                                        <FormControlLabel
                                                            value="Vendor"
                                                            control={<Checkbox disabled checked={field.value && field.value.includes('Vendor')} />}
                                                            label="Vendor"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}
                                            name="impactType"
                                            defaultValue={tq.impactType ?? null}
                                        />
                                    </Grid>
                                    <div className={styles.sectionDivider}>
                                        <h5>Part II - By Client</h5>
                                    </div>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p className={styles.required}>Response / Action</p>
                                                    <SunEditor
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        required
                                                        autoFocus={false}
                                                        setOptions={{
                                                            showPathLabel: false,
                                                            buttonList: [
                                                                ['undo', 'redo', 'bold', 'underline', 'italic', 'strike', 'fontColor', 'table'],
                                                            ],
                                                        }}
                                                        onChange={field.onChange}
                                                    />
                                                </div>
                                            )}
                                            name="response"
                                            defaultValue={tq.response ?? ''}
                                            rules={{
                                                required: 'Response is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div>
                                                    <div className={styles.radioGroup}>
                                                        <p className={styles.required}>Revised Deliverable Attached</p>
                                                        <RadioGroup
                                                            {...field}
                                                            row
                                                            onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                        >
                                                            <FormControlLabel value control={<Radio required/>} label="Yes" labelPlacement="top" />
                                                            <FormControlLabel value={false} control={<Radio required/>} label="No" labelPlacement="top" />
                                                        </RadioGroup>
                                                    </div>
                                                    {field.value && <FileDropZone callback={handleFileSelection} multiple />}
                                                </div>
                                            )}
                                            name="revisedDeliverableAttached"
                                            defaultValue={tq.revisedDeliverableAttached ?? null}
                                        />
                                    </Grid>
                                    {responderAttachments.length > 0 && (
                                        <Grid item xs={12} id="responder-attachments">
                                            <div className={styles.attachmentList}>
                                                <p>Attachments</p>
                                                <ul>
                                                    {responderAttachments.map(
                                                        (f): JSX.Element => (
                                                            <AttachmentListItem file={f} key={f.id} />
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField
                                                    {...field}
                                                    label="Answered By"
                                                    required
                                                    className={styles.required}
                                                    value={field.value ?? ''}
                                                    variant="standard"
                                                />
                                            )}
                                            name="answeredBy"
                                            defaultValue={tq.answeredBy ?? ''}
                                            rules={{
                                                required: 'Name is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    required
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    className={styles.required}
                                                />
                                            )}
                                            name="answered"
                                            defaultValue={tq.answered ?? null}
                                            rules={{
                                                required: 'Date is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => <SignatureControl field={field} title="Responder Signature" required />}
                                            name="answeredBySignature"
                                            defaultValue={tq.answeredBySignature ?? null}
                                            rules={{
                                                required: 'Signature is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <div className={styles.sectionDivider}>
                                        <h5>Part III - By H+M</h5>
                                    </div>
                                    <Grid item xs={7} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p>PM Approved</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio disabled />}
                                                            label="No"
                                                            labelPlacement="top"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="pmApproved"
                                            defaultValue={tq.pmApproved ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="pmApprovedDate"
                                            defaultValue={tq.pmApprovedDate ?? null}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                    </MuiPickersUtilsProvider>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button className="print-button" onClick={handlePrintPreview} disabled={parseInt(tqId, 10) < 0}>
                    Print
                </Button>
                <Button className="close-button" onClick={handleClose}>
                    Close
                </Button>
                <BusyButton className="save-button" onClick={onSubmit} busy={busy}>
                    Save
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
}

import {useContext, useEffect, useState} from 'react';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import SummaryCard from '~/components/generics/SummaryCard';
import InvoiceStatusChart from '~/components/visualizations/InvoiceStatusChart';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/ProjectSummaryDesktop.module.scss';

export default function SummaryInvoiceStatus(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {id} = project;
    const getProjectInvoices = useStoreActions((actions) => actions.accounting.getProjectInvoices);
    const [fetchingInvoices, setFetchingInvoices] = useState(false);

    useEffect(() => {
        if (id) {
            (async function fetchData(): Promise<void> {
                setFetchingInvoices(true);
                await getProjectInvoices(id);
                setFetchingInvoices(false);
            })();
        }
        return (): void => {
            setFetchingInvoices(false);
        };
    }, [getProjectInvoices, id]);

    return (
        <SummaryCard>
            <h3 className={styles.title}>Invoice Status</h3>
            {fetchingInvoices ? <LoadingComponent title="Fetching Invoices" contained/> : <InvoiceStatusChart activeOnly={false} />}
        </SummaryCard>
    );
}

import {ClickAwayListener} from '@mui/material';
import Fade from '@mui/material/Fade/Fade';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import {useSnackbar} from 'notistack';
import {useCallback, useContext, useState, useMemo} from 'react';
import * as React from 'react';
import {useLocation, useMatch, useParams, useSearchParams} from 'react-router-dom';
import { trackEvent } from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import FileDropZone from '~/components/generics/FIleDropZone';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DocumentDialog.module.scss';
import {StatusAction, UploadedFile} from '~/types';

interface ActionProps {
    action: StatusAction;
}

const Action = ({action}: ActionProps): JSX.Element => {
    const updateDocument = useStoreActions((actions) => actions.document.updateDocument);
    const postDocumentAttachments = useStoreActions((actions) => actions.document.postDocumentAttachments);
    const uploadFiles = useStoreActions((actions) => actions.shared.uploadFiles);

    const project = useStoreState((state) => state.subsite.project);
    const {document, handleClose} = useContext(DocumentTableContext);
    const [busy, setBusy] = useState<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const [comment, setComment] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<File[]>([]);
    const postComment = useStoreActions((actions) => actions.comment.postComment);
    const {projectId} = useParams<{projectId: string}>();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const match = useMatch('/project/:projectId/:page');
    const page = !match ? 'chat' : match.params.page;
    const docId = searchParams.get('doc');

    const commentRequired = useMemo((): boolean => action.action !== 'Approve', [action.action]);

    const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(e.currentTarget);
            setOpen(true);
    }
    
    const uploadAttachments = useCallback(async () => {
        if (attachments?.length > 0 && project?.projectNumber) {
            trackEvent('upload document attachments', {id: document.id, number: document.number, files: attachments.map(o => o.name)});
            const uploadedFiles: UploadedFile[] = await uploadFiles({
                destination: `/Development/Project/${project.projectNumber}/`,
                files: attachments,
            });
            const {result, message} = await postDocumentAttachments({
                projectId: parseInt(projectId, 10),
                docId: parseInt(docId, 10),
                attachments: uploadedFiles,
            });
            if (result === 'error') {
                enqueueSnackbar(message, {variant: 'error'});
            }
        }
    }, [attachments, docId, document.id, document.number, enqueueSnackbar, postDocumentAttachments, project.projectNumber, projectId, uploadFiles]);

    const handleConfirm = async (): Promise<void> => {
        if (commentRequired && comment.length === 0) {
            enqueueSnackbar('A comment is required for this action', {variant: 'error'});
            return;
        }

        if (comment.length > 255) {
            enqueueSnackbar(`The comment length, ${comment.length}, is over the 255 limit`, {variant: 'error'});
            return;
        }

        setBusy(true);
        await uploadAttachments();

        trackEvent('update document', {id: document.id, number: document.number});
        const promises: Array<Promise<any>> = [
            updateDocument({
                ...document,
                statusId: action.updateStatusTo ? action.updateStatusTo : document.statusId,
            }),
        ];

        if (comment.length > 0) {
            trackEvent('add document comment', {id: document.id, number: document.number});

            promises.push(
                postComment({
                    projectId: parseInt(projectId, 10),
                    comment,
                    docId: parseInt(docId, 10),
                    target: 'client;document',
                    url: location.pathname,
                    page,
                }),
            );
        }

        const [{result, message}] = await Promise.all(promises);

        setBusy(false);
        enqueueSnackbar(message, {variant: result});

        if (result !== 'error') {
            handleClose();
        }
    };

    const id = open ? 'comment-popper' : undefined;

    return (
        <ClickAwayListener
            onClickAway={(): void => {
                setOpen(false);
            }}>
            <div>
                <BusyButton onClick={handleClick} busy={busy} size="small" tooltip={action.description ?? ''} className={styles.statusActionButton}>
                    {action.action}
                </BusyButton>
                <Popper id={id} open={open} anchorEl={anchorEl} transition disablePortal placement="top">
                    {({TransitionProps}): JSX.Element => (
                        <Fade {...TransitionProps}>
                            <div className={styles.popper}>
                                <h3>{`${action.action} Comment${commentRequired ? ' Required' : ''}`}</h3>
                                <TextField
                                    multiline
                                    label="Comment"
                                    value={comment}
                                    fullWidth
                                    required={commentRequired}
                                    onChange={(e): void => setComment(e.target.value)}
                                    InputProps={{
                                        inputProps: {
                                            autoComplete: 'off',
                                            maxLength: 255,
                                        },
                                    }}
                                />
                                <FileDropZone callback={(files): void => setAttachments(files)} multiple uploadInProgress={busy} />
                                <div className={styles.popperActions}>
                                    <BusyButton onClick={handleConfirm} busy={busy} className="save-button">
                                        Confirm
                                    </BusyButton>
                                </div>
                                <span className={styles.arrow} />
                            </div>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default function DocumentActions(): JSX.Element {
    const {document} = useContext(DocumentTableContext);
    const {actionList} = document.status;
   // console.log(actionList)
    return (
        actionList.length > 0 &&
        !document.descendent && (
            <div className={styles.documentActionsRoot}>
                <h5>Available Actions</h5>
                <div>
                    {actionList.map((action) => (
                        <Action action={action} key={action.action} />
                    ))}
                </div>
            </div>
        )
    );
}
